<template>
  <div
    v-if="recent.length"
  >
    <transition-group
      class="grid grid-cols-1 gap-6"
      name="list"
      tag="div"
    >
      <automated-content-item
        v-for="(content) in recent"
        :key="content.id"
        :automatedContent="content"
        :can-create-post="!isPostLoaded"
        :can-copy="!isPostLoaded"
        :can-use="isPostLoaded"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import AutomatedContentItem from './AutomatedContentItem.vue'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  components: { AutomatedContentItem },
  setup () {
    const {
      recent,
      remove,
    } = useAutomatedContent()

    const {
      isPostLoaded,
    } = usePost()

    return {
      isPostLoaded,
      recent,
      remove
    }
  }
})
</script>

<style scoped>
  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all 0.3s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
  }
</style>