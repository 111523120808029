<template>
  <div v-if="!isLoaded" class="flex flex-col gap-4 mb-8">
    <div
      class="card animate-pulse flex flex-col relative"
      v-for="n in 12" :key="`preview-${n}`"
    >
      <div class="flex-auto border-gray-300 p-4">
        <div
          class="whitespace-normal font-flow text-3xl leading-5 text-gray-300 border-l-4 pl-3"
        >
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure, eum natus enim.
          <br>
          Lorem ipsum dolor
        </div>
      </div>
      <div class="card-footer flex items-center justify-end gap-4">
        <div class="bg-gray-300 rounded h-10 w-1/5 md:w-1/12"></div>
        <div class="bg-gray-300 rounded h-10 w-1/5 md:w-1/12"></div>
      </div>
    </div>
  </div>
  <div
    v-if="isLoaded && available.length"
  >
    <transition-group
      class="grid grid-cols-1 gap-6"
      name="list"
      tag="div"
    >
      <automated-content-item
        v-for="(automatedContent) in available"
        :key="automatedContent.id"
        :automatedContent="automatedContent"
        can-copy
        can-delete
        can-createPost
        show-content-props
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import AutomatedContentItem from './AutomatedContentItem.vue'

export default defineComponent({
  components: { AutomatedContentItem },

  setup () {
    const {
      isLoaded,
      available,
      remove,
    } = useAutomatedContent()

    return {
      isLoaded,
      available,
      remove
    }
  }
})
</script>

<style scoped>
.list-move, .list-enter-active, .list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from, .list-leave-to {
  opacity: 0;
}
</style>